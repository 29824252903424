"use client"
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext'; // Adjust the import path as necessary
import Session from "./session"
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Page = () => {


    // // Render nothing or a placeholder until redirection occurs
    return <React.Fragment>
        <Session />
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    </React.Fragment>
};

export default Page;
