import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from './AuthContext';

export enum Platform {
  AZURE = 'azure',
  CLAUDE = 'anthropic'
}

export enum Claude {
  SONNET = 'claude-3-sonnet-20240229',
  OPUS = 'claude-3-opus-20240229'
  // HAIKU = 'claude-3-haiku-20240307'
}

export enum GPT {
  GPT4 = 'galil-gpt-4',
  GPT3_5 = 'galil-gpt-35'
}

export const claudeOptions = [
  // { value: Claude.HAIKU, label: 'claude-3-haiku-20240307' },
  { value: Claude.SONNET, label: 'claude-3-sonnet-20240229' },
  { value: Claude.OPUS, label: 'claude-3-opus-20240229' }
];

// export const gptOptions = [
//   { value: GPT.GPT3_5, label: 'galil-gpt-35' },
//   { value: GPT.GPT4, label: 'galil-gpt-4' }
// ];

// const options = [...claudeOptions, ...gptOptions];
const PopOverAi = () => {
  const [isVisible, setIsVisible] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const { selectedModel, modelProvider, setSelectedModel } = useAuth();

  // Toggle the visibility of the popover
  const togglePopover = () => {
    setIsVisible(!isVisible);
  };

  // Close the popover if clicking outside of it
  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    // Add when mounted
    document.addEventListener('mousedown', handleClickOutside);
    // Return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle changes to the version dropdown
  const handlePrimaryChange = (event) => {
    setSelectedModel(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="relative group inline-block">
        {/* <button
          onClick={togglePopover}
          className="bg-teal-500 text-white px-4 py-2 rounded-lg hover:bg-teal-600 transition duration-300 ease-in-out transform hover:scale-105">
          AI Options
        </button> */}
        {isVisible && (
          <div
            ref={popoverRef}
            className="absolute w-64 mt-2  bg-white text-gray-800 border border-gray-300 rounded-lg shadow-lg py-2 z-10">
            <div className="flex flex-col gap-2 p-2">
              <div className="flex flex-col items-start">
                <div>Model:</div>
                <select
                  value={selectedModel}
                  onChange={handlePrimaryChange}
                  className="border border-gray-300 rounded p-2 w-full">
                  {claudeOptions.map((elem) => (
                    <option key={elem.value} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="flex flex-col items-start">
                <div>Secondary Model:</div>
                <select
                  value={selectedSecondary}
                  onChange={handleSecondaryChange}
                  className="border border-gray-300 rounded p-2 w-full">
                  {options.map((elem) => (
                    <option key={elem.value} value={elem.value}>
                      {elem.label}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default PopOverAi;
