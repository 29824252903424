import { franc } from 'franc';
import React, { useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';

const WithoutMarkDownComp = ({ text }: { text: string }) => {
  const [direction, setDirection] = useState<'ltr' | 'rtl'>('ltr');
  const textAlign = useMemo(() => (direction === 'rtl' ? 'right' : 'left'), [direction]);

  useEffect(() => {
    const langCode = franc(text, { minLength: 1 });
    // Set the direction based on the language code
    if (langCode === 'heb' || langCode === 'arb') {
      // Hebrew or Arabic
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  }, [text]);

  return (
    <div className="max-w-[70%] w-fit bg-slate-100 rounded-md">
      <div
        className="whitespace-pre-line p-2 break-words"
        style={{ direction: direction, textAlign: textAlign }}>
        {text?.replace(/^\s/, '').replace(/\s$/, '')}
      </div>
    </div>
  );
};

export default WithoutMarkDownComp;
