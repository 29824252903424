import './App.css';
import React from 'react';
import Provider from './Provider';
import Page from './page';
import { IntlProvider } from 'react-intl';
import messages_en from './locales/en.json';

const messages = {
  en: messages_en,
  he: messages_en
};

const language = navigator.language.split(/[-_]/)[0];

// const getTextDirection = (lang) => {
//   const rtlLanguages = ['he', 'ar']; // Add more RTL languages if needed
//   return rtlLanguages.includes(lang) ? 'rtl' : 'ltr';
// };

function App() {

  return (
    <div className="App">
      <Provider>
        <IntlProvider locale={navigator.language} messages={messages[language]}>
          <Page />
        </IntlProvider>
      </Provider>
    </div>
  );
}

export default App;
