import LOGO from './assets/cellcom_logo.png';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowUp, Loader2Icon, LoaderIcon } from 'lucide-react';
import Bubble from './Bubble';
// import { useSession } from 'next-auth/react';
// import { signIn } from 'next-auth/react';
import axiosinstance from './axiosInterceptorInstance';
import { Button } from './button';
import { useAuth } from './AuthContext';
import TextareaWithButton from './textareabutton';
import Loading from './Loading';
import Error from './Error';
// import { franc } from 'franc';
import { toast } from 'react-toastify';
import PopOverAi, { Platform } from './PopOver';
import LoadingDots from './LoadingDots';
import usePolling from './usePolling';
import DropDown from './Dropdown';
import { BASE_URL } from './axiosInterceptorInstance';
import { countryTranslationsHebrew } from './countries';

const contentGatheringData = `an agent will gather the data you need from the Database`;
export default function Home() {
  const { user, login, isLoadingLogin, isLoggedIn, error, selectedModel, modelProvider, pathId } =
    useAuth();

  const [message, setMessage] = useState('');
  const [items, setItems] = useState<Array<{ role: string; prompt: string }>>([]);
  const [sidechat, setSidechat] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [isUserSetupPending, setIsUserSetupPending] = useState(false);
  const [history, setHistory] = useState<any[]>([]);
  // const { history: history2 } = usePolling(user);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSend();
    }

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      setMessage((prev) => prev + '\n');
    }
  };

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef2 = useRef<HTMLDivElement | null>(null);

  const [canAutoScroll, setCanAutoScroll] = useState(true);
  const [canAutoScroll2, setCanAutoScroll2] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [chatActive, setChatActive] = useState(true);
  const [gatheringData, setGatheringData] = useState({ loading: false });
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const sideChatContainerRef = useRef<HTMLDivElement | null>(null);
  const [canChat, setCanChat] = useState(true);
  const [inputTokens, setInpuTokens] = useState(0);
  const [outputTokens, setOutputokens] = useState(0);
  
  // useEffect(() => {
  //   console.log('history2', history2);
  // }, []);
  const getCustomers = async () => {
    try {
      const response = await axiosinstance.get('/customers');
      setCustomers(response.data);
    } catch (error) {
      console.log('err', error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const getUserHistory = async () => {
    const chatHistory = await axiosinstance.get('/user_chat_history', {
      headers: {
        Authorization: `Bearer ${user.access_token}`
      }
    });

    setItems(
      chatHistory.data.map((elem: any) => ({
        role: 'K2',
        prompt: elem.content
      }))
    );
  };

  const eventSourceRef = useRef<EventSource | null>(null);

  const startStreaming = () => {
    try {
      if (user) {
        const access_token = user.access_token;

        if (access_token) {
          const url = `${BASE_URL}/stream?token=${encodeURIComponent(access_token)}`;

          eventSourceRef.current = new EventSource(url);

          eventSourceRef.current.onmessage = (event) => {
            const newMessage = JSON.parse(event.data);
            if (newMessage.endStream === true) {
              // setIsUserSetupPending(false);
              eventSourceRef.current?.close();
              eventSourceRef.current = null;
              return;
            } else if (newMessage.role === 'tokens_update') {
              console.log(newMessage.content);
              const input_tokens = newMessage.content.input_tokens;
              const output_tokens = newMessage.content.output_tokens;
              setInpuTokens((prev) => prev + input_tokens);
              setOutputokens((prev) => prev + output_tokens);

              return;
            } else if (!newMessage.content) {
              return;
            }
            // if (newMessage.content.includes(contentGatheringData)) {
            //   setGatheringData({ loading: true });
            // }
            setHistory((prevMessages) => [
              ...prevMessages,
              { ...newMessage, time: new Date().toLocaleString() }
            ]);
          };

          eventSourceRef.current.onerror = (error) => {
            console.error('EventSource failed:', error);
            // setIsUserSetupPending(false);
            eventSourceRef.current?.close();
            eventSourceRef.current = null;
          };
        }
      }
    } catch (error) {
      console.error('Error setting auth token', error);
    }
  };

  useEffect(() => {
    // Logic to handle component mount and unmount
    return () => {
      // Ensure to close the EventSource when the component unmounts
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      // setIsUserSetupPending(true);
      getUserHistory();
      startStreaming();
    }
  }, [isLoggedIn]);

  const handleScroll = useCallback(() => {
    // Check if the chat container scroll is at the bottom
    if (chatContainerRef.current) {
      const isScrolledToBottom =
        chatContainerRef.current.scrollHeight - chatContainerRef.current.clientHeight <=
        chatContainerRef.current.scrollTop + 1;
      setCanAutoScroll(isScrolledToBottom);
    }
  }, []);

  const handleScroll2 = useCallback(() => {
    // Check if the chat container scroll is at the bottom
    if (sideChatContainerRef.current) {
      const isScrolledToBottom =
        sideChatContainerRef.current.scrollHeight - sideChatContainerRef.current.clientHeight <=
        sideChatContainerRef.current.scrollTop + 1;
      setCanAutoScroll2(isScrolledToBottom);
    }
  }, []);

  useEffect(() => {
    // Attach scroll event listener
    const chatContainer = chatContainerRef.current;
    const sideChatContainer = sideChatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
    }

    if (sideChatContainer) {
      sideChatContainer.addEventListener('scroll', handleScroll2);
    }

    return () => {
      // Clean up the event listener
      if (chatContainer) chatContainer.removeEventListener('scroll', handleScroll);
      if (sideChatContainer) sideChatContainer.removeEventListener('scroll', handleScroll2);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (canAutoScroll) {
      messagesEndRef.current?.scrollIntoView({
        behavior: 'instant'
      });
    }
  }, [items, canAutoScroll]);

  useEffect(() => {
    if (!chatActive) {
      messagesEndRef.current?.scrollIntoView({
        behavior: 'instant'
      });
    }
  }, [chatActive]);

  useEffect(() => {
    if (canAutoScroll2) {
      messagesEndRef2.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [canAutoScroll2, history]);

  const onSend = async () => {
    if (isLoading) return;
    if (!message.trim()) return;
    if (!isLoggedIn) return;
    if (!canChat) return;
    setCanChat(false);
    startStreaming();

    function processChunks(chunks) {
      let result = '';
      let buffer = '';
      let foundTerminator = false;

      for (let i = 0; i < chunks.length; i++) {
        if (foundTerminator) {
          break;
        }

        buffer += chunks[i];

        // Check if buffer contains ">>>"
        while (buffer.length >= 3) {
          const index = buffer.indexOf('>>>');
          if (index !== -1) {
            // Found the terminator
            result += buffer.slice(0, index);
            foundTerminator = true;
            break;
          } else {
            // Move the last two characters to the start of buffer for the next iteration
            if (buffer.length > 2) {
              result += buffer.slice(0, buffer.length - 2);
              buffer = buffer.slice(buffer.length - 2);
            } else {
              break;
            }
          }
        }
      }

      // Append the remaining buffer if it does not contain ">>>"
      if (!foundTerminator) {
        result += buffer;
      }

      return result;
    }

    const save = !message.includes('#####');

    try {
      if (save) {
        setIsLoading(true);
        setItems((prev) => [
          ...prev,
          {
            role: message.includes('###') ? 'K2' : 'user',
            prompt: message
          }
        ]);
      }

      setMessage('');
      console.log('models:', selectedModel);

      const primary = JSON.stringify({
        provider: 'anthropic', // example value
        model: selectedModel
      });
      const secondary = JSON.stringify({
        provider: 'anthropic', // example value
        model: selectedModel
      });

      const url = `${BASE_URL}/message?message=${encodeURIComponent(message)}&primary=${encodeURIComponent(primary)}&secondary=${encodeURIComponent(secondary)}&token=${encodeURIComponent(user.access_token)}&path=${encodeURIComponent(pathId)}`;

      const eventSource = new EventSource(url);

      let responseChunks: any = [];
      let initialItemCreated = false;
      let addMessage = true;
      const processResponseChunks = (text) => {
        while (text.endsWith('>')) {
          text = text.slice(0, -1);
          addMessage = false;
        }
        return text;
      };

      let buffer = '';

      eventSource.onmessage = (event) => {
        console.log('Message received:', event);
        const data: any = JSON.parse(event.data);
        let msg = data.message;
        if (data.endStream) {
          eventSource.close();
          setCanChat(true);
        } else {
          buffer += data.message;

          responseChunks.push(msg);
          if (save && addMessage) {
            setIsLoading(false);
            setItems((prev) => {
              const newItems = [...prev];
              if (!initialItemCreated) {
                // Create the initial item
                newItems.push({
                  role: 'K2',
                  prompt: processChunks(responseChunks.join(''))
                });
                initialItemCreated = true;
              } else {
                // Update the last item with the new chunks
                newItems[newItems.length - 1] = {
                  ...newItems[newItems.length - 1],
                  prompt: processChunks(responseChunks.join(''))
                };
              }
              return newItems;
            });
          }
        }
      };

      eventSource.onerror = (error) => {
        console.error('EventSource error:', error);
        console.error('EventSource state:', eventSource.readyState);
        console.error('EventSource URL:', eventSource.url);

        setItems((prev) => [
          ...prev,
          {
            role: 'K2',
            prompt: 'Error in server'
          }
        ]);
        toast.error('Error or token expired, please start a new chat');
        setChatActive(false);
        eventSource.close();
        setIsLoading(false);
      };

      eventSource.onopen = () => {
        console.log('EventSource opened:', eventSource);
        setCanAutoScroll(true);
      };
    } catch (error) {
      console.error('Error in onSend function:', error);
      setItems((prev) => [
        ...prev,
        {
          role: 'K2',
          prompt: 'Error in server'
        }
      ]);

      toast.error('Error or token expired, please start a new chat');
      setCanChat(false);
      setChatActive(false);
      setIsLoading(false);
    }
  };
  // const onSend = async () => {
  //   if (isLoading) return;
  //   if (!message.trim()) return;
  //   if (!isLoggedIn) return;

  //   startStreaming();

  //   const save = !message.includes('#####');

  //   try {
  //     if (save) {
  //       setIsLoading(true);
  //       setItems((prev) => [
  //         ...prev,
  //         {
  //           role: message.includes('###') ? 'K2' : 'user',
  //           prompt: message
  //         }
  //       ]);
  //     }

  //     setMessage('');
  //     console.log('models:', selectedModel);
  //     const res = await axiosinstance.post(
  //       '/message',
  //       {
  //         message: message,
  //         primary: {
  //           model: selectedModel,
  //           provider: 'anthropic'
  //         },
  //         secondary: {
  //           model: selectedModel,
  //           provider: 'anthropic'
  //         }
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${user.access_token}`
  //         }
  //       }
  //     );

  //     if (res.data.close_session === 1) {
  //       setChatActive(false);
  //     } else {
  //       if (save) {
  //         // Handle normal message response
  //         setItems((prev) => [...prev, { role: 'K2', prompt: res.data.message }]);
  //         setGatheringData({ loading: false });
  //       }
  //     }
  //     setCanAutoScroll(true);
  //   } catch (error) {
  //     setItems((prev) => [
  //       ...prev,
  //       {
  //         role: 'K2',
  //         prompt: 'Error in server'
  //       }
  //     ]);

  //     toast.error('Error or token expired, please start a new chat');
  //     setChatActive(false);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // const [routeId, setRouteId] = useState('');

  // useEffect(() => {
  //   // Assuming the URL is like "http://localhost:3000/2bb7fde6-a9fa-4d00-b692-25b62fc98ff9"
  //   // and you want to extract the UUID part
  //   const pathArray = window.location.pathname.split('/');
  //   const id = pathArray[1];
  //   setRouteId(id);
  // }, []);

  const startNewChat = async (event) => {
    // if (event.shiftKey) {
    //   try {
    //     const res = await axiosinstance.post('/newchat', {
    //       id: 'customer.customer_id'
    //     });
    //     if (res.data.success) {
    //       window.location.reload();
    //     }
    //   } catch (err) {
    //     console.log('err', err);
    //   }
    // } else {
    //   window.location.reload();
    // }
    window.location.reload();
  };

  const [inputLanguage, setInputLanguage] = useState<string>('heb');

  const direction = useMemo<'ltr' | 'rtl'>(() => {
    if (['heb', 'arb', 'amh'].includes(inputLanguage)) {
      return 'rtl';
    }
    return 'ltr';
  }, [inputLanguage]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    setMessage(newText);
    const languageWhitelist = ['eng', 'heb', 'arb', 'fra', 'amh'];

    const charSets = {
      eng: /[A-Za-z]/g,
      heb: /[א-ת]/g,
      arb: /[ء-ي]/g,
      fra: /[A-Za-zÀ-ÿ]/g,
      amh: /[ሀ-፼]/g,
      rus: /[А-яЁё]/g
    };
    const alphabetCount = newText.split('').filter((char) => /\p{L}/u.test(char)).length;
    let detectedLang = inputLanguage;
    if (alphabetCount) {
      //count chars to detect language
      let langCounts = {};
      for (const [lang, regex] of Object.entries(charSets)) {
        langCounts[lang] = newText.match(regex || [])?.length || 0;
      }

      detectedLang = Object.keys(langCounts).reduce((prev, curr) => {
        if (langCounts[curr] > langCounts[prev]) return curr;
        else return prev;
      }, 'eng');

      if (!languageWhitelist.includes(detectedLang)) {
        detectedLang = inputLanguage;
      }
    }
    setInputLanguage(detectedLang);

    // if (alphabetCount) {
    //   langCode = franc(newText, { minLength: 1 });
    //   if (languageWhitelist.includes(langCode)) {
    //     setInputLanguage(langCode);
    //   }
    // }
  };

  const customersResult = useMemo(
    () =>
      customers.filter(
        (customer) =>
          customer.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          customer.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [customers, searchQuery]
  );

  // useEffect(() => {
  //   // login('9999999999');
  // }, [login]);
  // const [routeId, setRouteId] = useState('');

  // useEffect(() => {
  //   // Assuming the URL is like "http://localhost:3000/2bb7fde6-a9fa-4d00-b692-25b62fc98ff9"
  //   // and you want to extract the UUID part
  //   const pathArray = window.location.pathname.split('/');
  //   const id = pathArray[1];
  //   setRouteId(id);
  // }, []);

  return (
    <div className="relative w-screen h-screen flex">
      {isLoadingLogin && <Loading message="Starting Session..." />}

      {error && <Error message="Unauthorized" />}
      {!isLoggedIn && (
        <div className="fixed top-0 left-0 w-screen h-screen z-20 flex items-center justify-center inset-0 bg-black bg-opacity-80">
          <div className="bg-white p-8 rounded-lg shadow-2xl flex flex-col gap-2 justify-start items-center h-[80%] w-[80%]">
            <form className="max-w-md w-full">
              <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
                Search
              </label>
              <div style={{ direction: 'rtl' }} className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  id="default-search"
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                  placeholder="חיפוש משתמשים"
                  required
                />
                <button
                  type="submit"
                  className="text-white absolute end-2.5 bottom-2.5 bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
                  חיפוש
                </button>
              </div>
            </form>
            <div
              style={{ direction: 'rtl' }}
              className="grid lg:grid-cols-4 h-full overflow-auto scrollbarStyled gap-[10px]">
              {customersResult.map((customer) => (
                <div
                  key={customer}
                  className="m-2 bg-white rounded-lg shadow-xl lg:flex lg:max-w-lg w-[300px] h-[200px] transform transition duration-500 
                                hover:scale-110 cursor-pointer"
                  onClick={() => login(customer.customer_id)}>
                  <div className="p-6 bg-gray-50  w-full h-full flex flex-col justify-between">
                    <div className="flex items-center gap-4">
                      <div className="font-medium dark:text-white flex flex-col items-start">
                        <div>מנוי: {customer.customer_id}</div>
                        <div className="flex flex-col gap-1 justify-start items-start">
                          <div className="text-gray-600 dark:text-gray-300">
                            שם: {customer.customer_name}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-1 items-start justify-start mt-2 h-full border-gray-500 border rounded-md scrollbarStyled overflow-auto">
                      <div className="  mt-1 col px-1 w-full flex flex-col">
                        {customer.subscriber_numbers.map((number, index) => (
                          <div key={index}>
                            <div className="text-[13px] text-right">{number}</div>
                            {/* <div className="text-[13px] text-right">
                              שימוש: {elem.balance_gb}GB / {elem.total_surf_volume_gb}GB
                            </div>
                            <div className="text-[13px] text-right">
                              {elem.start_date} / {elem.end_date}
                            </div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div
        className={`flex flex-col flex-shrink-0 justify-center items-center ${sidechat ? 'w-[65%]' : 'w-full'}`}>
        <div className="flex">
          <div className="absolute inset-0 bg-gradient-to-b from-purple-100 via-purple-100 to-purple-500 h-1/2" />
          <div className="relative z-10 max-w-2xl mx-auto flex flex-col justify-between items-center p-10 ">
            <img src={LOGO} alt={''} className="w-100 pt-6 h-[128px] w-auto" />
            <p className=" font-bold text-black text-3xl pt-2">?כיצד אוכל לעזור לך היום</p>
          </div>
        </div>
        <div
          className="flex flex-col justify-between rounded-xl border bg-white shadow relative z-10 p-1 m-10 w-8/12 scrollbarStyled"
          style={{ height: 'calc(100% - 300px)' }}>
          {isUserSetupPending && <Loading message="Generating User Info..." />}
          <div className="flex flex-col pt-10 overflow-auto h-full px-20" ref={chatContainerRef}>
            <DropDown />
            {items.map((el, index) => (
              <Bubble
                key={index}
                message={{
                  role: el.role,
                  prompt: el.prompt
                }}
                mainchat={true}
              />
            ))}
            {isLoading && <LoadingDots direction={direction} language={inputLanguage} />}

            {gatheringData.loading && (
              <div className="flex items-center ml-[40px]">
                <div role="status">
                  {/* {gatheringData.success ? <svg className="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0 animate-appear" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> : */}
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                Gathering data about the customer...
              </div>
            )}

            {!chatActive && (
              <div className="bg-red-500 text-white text-center p-2 mt-2 rounded sticky bottom-0 left-0">
                The chat session has ended.
              </div>
            )}
            <div ref={messagesEndRef} style={{ width: '100%' }} />
          </div>
          <div className="flex px-20 py-4 justify-center items-center gap-3">
            <TextareaWithButton
              autoComplete="off"
              value={message}
              onKeyDown={handleKeyPress}
              onChange={handleInputChange}
              name="message"
              placeholder="מקלידים כאן..."
              className="w-full flex items-center resize-none overflow-hidden bg-background"
              buttonText={''}
              style={{ direction: direction }}
              // disabled={isLoading || !chatActive ? true : false}
              onButtonClick={onSend}>
              {isLoading || !canChat ? (
                <div className="opacity-50 bg-purple-500 text-white font-bold py-2 px-4 flex items-center justify-center h-full rounded cursor-disabled w-4">
                  <div>
                    <ArrowUp className="w-5 h-5" />
                  </div>
                </div>
              ) : (
                <div className=" bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 flex items-center justify-center h-full rounded cursor-pointer w-4">
                  <div>
                    <ArrowUp className="w-5 h-5" />
                  </div>
                </div>
              )}
            </TextareaWithButton>
          </div>
        </div>
      </div>
      <div
        ref={sideChatContainerRef}
        className={` fixed top-0 right-0 ${sidechat ? '' : 'translate-x-full'}  h-screen  w-[45%] bg-white z-10 flex-grow-0 flex-shrink-0 basis-[45%] shadow-2xl ease-in-out duration-300 from-he flex flex-col gap-3 items-start `}>
        {/* <div className="h-10 bg-purple-100 w-full flex justify-center items-center">
          Customer: {customer.first_name} {customer.last_name}
        </div> */}
        <div className="h-full scrollbarStyled overflow-auto p-4 w-full">
          {history.map((el, i) => (
            <React.Fragment key={i}>
              <Bubble
                message={{
                  role: el.role,
                  prompt: el.content,
                  time: el.time,
                  prompt_history: el.prompt_history
                }}
                divider={el.type === 'user' ? true : false}
                markdownDisabled={true}
              />
            </React.Fragment>
          ))}
        </div>
        <div className="p-2 flex items-center w-full justify-center">
          input tokens: {inputTokens} - output tokens: {outputTokens}
        </div>
        <div ref={messagesEndRef2} style={{ width: '100%' }} />
      </div>
      <div className="text-center fixed top-2 left-2 z-10">
        <button
          onClick={() => setSidechat((prev) => !prev)}
          className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
          type="button"
          data-drawer-target="drawer-navigation"
          data-drawer-show="drawer-navigation"
          aria-controls="drawer-navigation">
          {sidechat ? 'Hide' : 'Show'} full history
        </button>
      </div>
      <div className="text-center fixed top-2 left-[170px] z-10">
        <button
          onClick={startNewChat}
          className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
          type="button"
          data-drawer-target="drawer-navigation"
          data-drawer-show="drawer-navigation"
          aria-controls="drawer-navigation">
          Start new chat
        </button>
      </div>
      <div className="text-center fixed top-[60px] left-2 z-10">
        <PopOverAi />
      </div>
    </div>
  );
}

// export async function getServerSideProps({ params }: any) {
//   // Fetch data based on params.id
//   const res = await fetch(`http://localhost:5000/session`);
//   const session = await res.json();
//   console.log(session)
//   return {
//     session
//   };
// }
